//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@keyframes primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }

  20% {
    animation-timing-function: cubic-bezier(.5, 0, .701732, .495819);
    transform: translateX(0);
  }

  59.15% {
    animation-timing-function: cubic-bezier(.302435, .381352, .55, .956352);
    transform: translateX(83.67142%);
  }

  100% {
    transform: translateX(200.611057%);
  }
}

@keyframes primary-indeterminate-scale {
  0% {
    transform: scaleX(.08);
  }

  36.65% {
    animation-timing-function: cubic-bezier(.334731, .12482, .785844, 1);
    transform: scaleX(.08);
  }

  69.15% {
    animation-timing-function: cubic-bezier(.06, .11, .6, 1);
    transform: scaleX(.661479);
  }

  100% {
    transform: scaleX(.08);
  }
}

@keyframes secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(.15, 0, .515058, .409685);
    transform: translateX(0);
  }

  25% {
    animation-timing-function: cubic-bezier(.31033, .284058, .8, .733712);
    transform: translateX(37.651913%);
  }

  48.35% {
    animation-timing-function: cubic-bezier(.4, .627035, .6, .902026);
    transform: translateX(84.386165%);
  }

  100% {
    transform: translateX(160.277782%);
  }
}

@keyframes secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(.205028, .057051, .57661, .453971);
    transform: scaleX(.08);
  }

  19.15% {
    animation-timing-function: cubic-bezier(.152313, .196432, .648374, 1.004315);
    transform: scaleX(.457104);
  }

  44.15% {
    animation-timing-function: cubic-bezier(.257759, -.003163, .211762, 1.38179);
    transform: scaleX(.72796);
  }

  100% {
    transform: scaleX(.08);
  }
}

@keyframes buffering {
  to {
    transform: translateX(-10px);
  }
}

@keyframes primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(0);
  }

  20% {
    animation-timing-function: cubic-bezier(.5, 0, .701732, .495819);
    transform: translateX(0);
  }

  59.15% {
    animation-timing-function: cubic-bezier(.302435, .381352, .55, .956352);
    transform: translateX(-83.67142%);
  }

  100% {
    transform: translateX(-200.611057%);
  }
}

@keyframes secondary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(.15, 0, .515058, .409685);
    transform: translateX(0);
  }

  25% {
    animation-timing-function: cubic-bezier(.31033, .284058, .8, .733712);
    transform: translateX(-37.651913%);
  }

  48.35% {
    animation-timing-function: cubic-bezier(.4, .627035, .6, .902026);
    transform: translateX(-84.386165%);
  }

  100% {
    transform: translateX(-160.277782%);
  }
}

@keyframes buffering-reverse {
  to {
    transform: translateX(10px);
  }
}
